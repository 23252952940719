@import '../../style/themes/index';
@import '../../style/mixins/index';

@space-prefix-cls: ~'@{ant-prefix}-space';

.@{space-prefix-cls} {
  display: inline-flex;
  &-vertical {
    flex-direction: column;
  }

  &-align {
    &-center {
      align-items: center;
    }
    &-start {
      align-items: flex-start;
    }
    &-end {
      align-items: flex-end;
    }
    &-baseline {
      align-items: baseline;
    }
  }
}

@import './rtl';

@font-family: Geometria, Arial, sans-serif;@primary-color: #2B3D4E;@btn-primary-bg: #F54D2E;@btn-ghost-color: #F54D2E;@btn-ghost-border: #F54D2E;@btn-border-radius-base: 4px;@btn-padding-horizontal-base: 20px;@input-padding-horizontal: 16px;@btn-padding-horizontal-lg: 20px;@input-height-base: 42px;@btn-height-lg: 44px;@layout-body-background: #ffffff;@layout-trigger-background: #2B3D4F;@layout-trigger-color: rgba(0, 0, 0, .5);@font-family-no-number: Geometria, Arial, sans-serif;@layout-header-padding: 0 24px;@menu-item-height: 75px;@menu-dark-item-active-bg: transparent;@menu-dark-bg: #2B3D4F;@border-radius-base: 4px;@height-base: 40px;@btn-primary-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);@checkbox-size: 20px;